import React from 'react'
import {
    Flex,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    FormErrorMessage,
    Button,
    CircularProgress,
    Box,
    Text
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import { Link } from 'react-router-dom'


const FormularioInicial = (props) => {

    const { setDataStart, telefono, email } = props

    return (
        <Flex flexDirection={"column"} alignItems={"center"} w={'100%'} mt={6} mb={6}>
            <Box rounded={'lg'} bg={"white"} boxShadow={'lg'} p={8} w={"full"} maxW={"900px"}>
                <Formik
                    initialValues={{
                        telefono: telefono,
                        email: email
                    }}
                    validate={values => FormValidator([
                        { field: 'telefono', value: values.telefono, rules: ['required'], lang: 'Teléfono' },
                        { field: 'email', value: values.email, rules: ['required'], lang: 'Correo' },
                    ])}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        Promise.resolve(setDataStart(values))
                            .then(setSubmitting(false))
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>

                            <FormControl isInvalid={errors.telefono && touched.telefono}>
                                <FormLabel>Teléfono</FormLabel>
                                <InputGroup>
                                    <InputLeftAddon children={"+503"} />
                                    <Input type={'number'} name={"telefono"} onChange={handleChange} onBlur={handleBlur} value={values.telefono} />
                                </InputGroup>
                                <FormErrorMessage>{errors.telefono}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.email && touched.email}>
                                <FormLabel>Correo</FormLabel>
                                <Input type={'email'} name={"email"} onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                <FormErrorMessage>{errors.email}</FormErrorMessage>
                            </FormControl>

                            <br />
                            <Text>Al hacer clien en "Continuar" confirmo que he leido los <b><Link to={"/terminos-condiciones"} target="_blank" rel="noopener noreferrer">términos y condiciones</Link></b> y <b><Link to={"/aviso-privacidad"} target="_blank" rel="noopener noreferrer">aviso de privacidad</Link></b> de Easycar</Text>

                            <Button w={"full"} colorScheme="easycar2" type="submit" mt={4} disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress /> : 'Continuar'}
                            </Button>
                        </form>
                    )}
                </Formik>
            </Box>
        </Flex>
    )
}

export default FormularioInicial