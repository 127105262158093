import React from "react"
import { Flex, Box, Heading, Text } from '@chakra-ui/react'
import Layout from '../layout'


const Aviso_privacidad = (props) => {

    return (
        <Layout>


            <Box w={"full"} >
                <Flex maxW={"1400px"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"} mt={"7.5rem"}>
                    <Box flex={1} order={{ base: 2, md: 2 }} p={"10"} margin={"auto"} >
                        <Heading>Aviso de privacidad</Heading>
                        <br />
                        <Text as={"b"}>POLÍTICA DE PRIVACIDAD DE INVERSIONES FUTURO, LTDA. DE C.V. </Text>
                        <Text>
                            La PÁGINA WEB de INVERSIONES FUTURO, LTDA. DE C.V., está comprometida a garantizar la confidencialidad de sus servicios y productos. Lea lo siguiente para entender el tratamiento que damos a su información personal cuando utiliza nuestros servicios.
                            <br />POLÍTICA DE PRIVACIDAD
                            <br />A fin de preservar la confidencialidad de toda la información que nos suministran, hemos adoptado los siguientes principios de privacidad:
                            <br />• Sólo recabamos aquella información que consideramos relevante y necesaria para comprender sus necesidades y desarrollar nuestro negocio.
                            <br />• Utilizamos su información con el objetivo de brindarle mejores productos y una mejor atención al cliente.
                            <br />• Compartiremos su información con otras compañías o agentes del Grupo al que pertenece INVERSIONES FUTURO, LTDA. DE C.V., de acuerdo con las leyes locales vigentes.
                            <br />• No suministraremos información a ninguna organización externa, a menos que contemos con su consentimiento o lo requiera la ley o le hayamos informado con anterioridad. Ocasionalmente, podrían requerirnos que le suministremos información a organismos gubernamentales o judiciales o a nuestro ente regulador, pero sólo lo haremos con la autorización pertinente.
                            <br />• Nuestro objetivo es mantener su información actualizada.
                            <br />• Contamos con estrictos sistemas de seguridad diseñados especialmente para prevenir el acceso no autorizado a su información por parte de cualquier persona, incluso nuestro personal.
                            <br />• Todas las compañías del Grupo, el personal y los terceros que poseen autorización para acceder a su información deberán observar específicamente nuestras obligaciones de confidencialidad.
                            <br />• Al mantener nuestro compromiso con dichos principios, nosotros, en INVERSIONES FUTURO, LTDA. DE C.V., les aseguraremos que respetamos la confianza que depositan en nosotros.
                            <br />• En la PÁGINA WEB podrá incluir información con la finalidad de ser contactado por uno de nuestros funcionarios de servicios, la información que solicitamos es tal como número de celular, correo electrónico, nombre, ingresos, los cuales será de uso exclusivo para contactarle y atenderle de forma eficiente. También podrá encontrar la información de nuestras agencias, así como cotizar nuestras opciones de servicios y productos, envío de documentos para abrir productos, cuando lo solicite y este de acuerdo.
                            <br />RECOPILACIÓN Y USO DE INFORMACIÓN
                            <br />INVERSIONES FUTURO, LTDA. DE C.V. podrá invitar y solicitar información personal de usuarios con la finalidad de poder comunicar y notificar a los usuarios de éste canal (explicar en dónde y cómo), información y promociones sobre productos o servicios de INVERSIONES FUTURO, LTDA. DE C.V., para visitar sitios dentro de INVERSIONES FUTURO, LTDA. DE C.V. o para poder participar en promociones y/o eventos especiales.
                            <br />Cuando usted ingresa información en la PÁGINA WEB de INVERSIONES FUTURO, LTDA. DE C.V. nosotros le solicitamos los siguientes datos:
                            <br />a)	Preferencia referente a recibir información de productos y servicios de INVERSIONES FUTURO, LTDA. DE C.V.
                            <br />b)	Aceptación para enviar información relevante como temas de seguridad y nuevas medidas de seguridad, a su vez eventualmente y dependiendo de que sea implementada alguna promoción que requiera otro tipo de información se podrá solicitar corroborar información o proporcionarnos nuevos datos que pueden ser nombre, correo-e, fecha de nacimiento, sexo, zona postal, profesión y algunos gustos personales. Al finalizar de incorporar información en la PÁGINA WEB y cuando comienza a recibir nuestros correos electrónicos, usted ya es una persona identificable para nosotros.
                            <br /> Y REVELAR INFORMACIÓN
                            <br />Para compartir o revelar información de nuestros clientes, nos regiremos por las Leyes aplicables y por la Declaración de Privacidad establecida en el presente documento, antes descrita.
                            <br />SEGURIDAD
                            <br />IMPORTANTE: INVERSIONES FUTURO, LTDA. DE C.V. nunca envía a sus clientes correos electrónicos solicitando confirmar, validar o actualizar información CONFIDENCIAL respecto a sus CUENTAS, CLAVES DE ACCESO, CONTRASEÑAS, INFORMACIÓN DE SUS PRODUCTOS O SERVICIOS. Si usted recibe un correo que le solicite estos datos, por favor repórtelo al número telefónico: (503)72900087. En los casos de estar afiliado a algún tipo de servicio en donde se envíe información confidencial como estados de cuenta, INVERSIONES FUTURO, LTDA. DE C.V. se compromete a enviar la misma bajo sistemas de seguridad, con lo que se garantiza que la información que está siendo compartida con usted, es transmitida de forma segura entre su computadora y INVERSIONES FUTURO, LTDA. DE C.V.
                            <br />MODIFICACIONES A ESTAS POLÍTICAS DE PRIVACIDAD
                            <br />INVERSIONES FUTURO, LTDA. DE C.V., realiza enmiendas a estas políticas ocasionalmente. Si los cambios que realizamos son importantes y afectan directamente al uso que le damos a la información personal de nuestros usuarios, se los haremos saber colocando en nuestras páginas avisos destacados al respecto.
                            <br />DUDAS O SUGERENCIAS
                            <br />Si tiene dudas o sugerencias respecto a nuestras Políticas de Privacidad o ante cualquier situación que viole o atente contra las políticas de seguridad descritas anteriormente, favor reportarlas al (503)72900087.
                        </Text>

                    </Box>

                </Flex>
            </Box>

        </Layout >
    )
}

export default Aviso_privacidad