import { Box, Image, Text, Heading, Badge } from '@chakra-ui/react'
import { Link, useHistory } from 'react-router-dom'
import placeholder from '../multimedia/placeholder.jpg'
import { APIpath } from '../actions'
import { StarIcon } from '@chakra-ui/icons'
import { MdLocationOn } from 'react-icons/md'


function CarCard(props) {

    const { car } = props

    const replaceImage = (error) => {
        error.target.src = placeholder;
    }

    let history = useHistory()

    function changeLocation(placeToGo) {
        history.push(placeToGo, { replace: true });
        window.scrollTo(0, 0);
    }

    return (

        <Box
            bg={'white'}
            maxW="350px"
            maxH={"420px"}
            height={"full"}
            borderWidth="1px"
            rounded="lg"
            shadow="lg"
            className='miniCarIcon'
            position="relative">

            <Link to={`/car/${car.id}`} onClick={() => changeLocation(`/car/${car.id}`)}>
                {car.bDestacado &&
                    <Badge colorScheme='easycar1' variant={"solid"} fontSize='1em' position={"absolute"} margin={"3px"}>
                        <StarIcon color={"yellow.500"} />   Destacado
                    </Badge>
                }
                <Image alt={`Preview ${car.cMarca}`} src={`${APIpath}/carro/image/${car.id}/1`} fit={'cover'} align={'center'} w={'100%'} h={'200px'} onError={replaceImage} />
                <Box p="6" minH={"220px"}>
                    <Heading as={'h5'} size='md'>{car.cMarca.toUpperCase()} {car.cModelo.toUpperCase()}</Heading>
                    <Text>
                        {car.nAño} - {car.cTransmision} - {car.nRecorrido.toLocaleString('en-US')} {car.cRecorridoTipo}
                    </Text>
                    <Text color={"green"} as={"b"} >Precio en arrendamiento: </Text><Text> ${car.nValor.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
                    <Text>Prima desde: {car.nPrima.toLocaleString('en-US')}%*</Text>
                    <Text fontSize={"xs"}>Monto de prima, sujeto a precalificación</Text>
                    { car.autolote &&
                        <Text> <MdLocationOn display={"inline"}  /> {car.autolote}</Text>

                    }
                </Box>
            </Link>
        </Box >
    )
}

export default CarCard