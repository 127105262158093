import { Card, CardBody, Image, Text, Flex } from '@chakra-ui/react'
import Layout from '../layout'
import agencia1 from '../multimedia/imagenes/escalon.jpg'
import agencia2 from '../multimedia/imagenes/santaana.jpg'
import agencia3 from '../multimedia/imagenes/sanmiguel.jpg'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import ModalApp from "../containers/Modal"

const Agencias = () => {

    return (
        <Layout>
            <LoadScript googleMapsApiKey="AIzaSyCggvraT4pGMXL0J4wLlfp_b5yoViJ95EY" >
                <Flex flexDir={{ base: 'column', lg: 'row' }} justifyContent={"space-around"} alignItems={"center"} p={"6"} mt={"10px"} gap={"10px"}>

                    <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                        <Image src={agencia1} h={"300px"} />
                        <CardBody>
                            <Text as={"b"}>Escalón</Text>
                            <Text> 89 Av Norte y 1era Calle Poniente Plaza 89,<br /> Local 4. Colonia Escalón, San Salvador. </Text>
                            <Text as={"b"}>Télefonos:</Text>
                            <Text>2535-5976 y 7567-3475</Text>
                            <ModalApp title={"Easycar Escalón"} >

                                <GoogleMap
                                    zoom={16}
                                    mapContainerStyle={{ width: '100%', height: '500px' }}
                                    center={{ lat: 13.70410605023694, lng: -89.24176707116466 }}
                                >
                                    <Marker position={{ lat: 13.70410605023694, lng: -89.24176707116466 }} ></Marker>
                                </GoogleMap>
                                <br />
                                <Text as={"b"}>Ubicación:</Text>
                                <Text>89 Av Norte y 1era Calle Poniente Plaza 89,<br /> Local 4. Colonia Escalón, San Salvador.</Text>
                                <br />
                                <Text as={"b"}>Horario:</Text>
                                <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                <Text>Sábado de 8:00 am a 12:00 md</Text>
                                <br />
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>2535-5976 y 7567-3475</Text>
                            </ModalApp>
                        </CardBody>
                    </Card>

                    <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                        <Image src={agencia2} h={"300px"} />
                        <CardBody>
                            <Text as={"b"}>Santa Ana</Text>
                            <Text>Centro Comercial Plaza Bolcaña, Local6,<br />Av. Fray Felipe de Jesús Sur, Santa Ana.</Text>
                            <Text as={"b"}>Télefonos:</Text>
                            <Text>2535-5977 y 7734-6872</Text>
                            <ModalApp title={"Easycar Santa Ana"} >

                                <GoogleMap
                                    zoom={16}
                                    mapContainerStyle={{ width: '100%', height: '500px' }}
                                    center={{ lat: 13.978647416737255, lng: -89.56907257116468 }}
                                >
                                    <Marker position={{ lat: 13.978647416737255, lng: -89.56907257116468 }} ></Marker>
                                </GoogleMap>
                                <br />
                                <Text as={"b"}>Ubicación:</Text>
                                <Text>Centro Comercial Plaza Bolcaña, Local6,<br />Av. Fray Felipe de Jesús Sur, Santa Ana.</Text>
                                <br />
                                <Text as={"b"}>Horario:</Text>
                                <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                <Text>Sábado de 8:00 am a 12:00 md</Text>
                                <br />
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>2535-5977 y 7734-6872</Text>
                            </ModalApp>
                        </CardBody>
                    </Card>

                    <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                        <Image src={agencia3} h={"300px"} />
                        <CardBody>
                            <Text as={"b"}>San Miguel</Text>
                            <Text>2da Avenida Norte, sobre Ruta Militar, San Miguel</Text>
                            <Text as={"b"}>Télefonos:</Text>
                            <Text>7858 1276</Text>
                            <ModalApp title={"Easycar San Miguel"} > ,

                                <GoogleMap
                                    zoom={16}
                                    mapContainerStyle={{ width: '100%', height: '500px' }}
                                    center={{ lat: 13.49279287069646, lng: -88.17746554232934 }}
                                >
                                    <Marker position={{ lat: 13.49279287069646, lng: -88.17746554232934 }} ></Marker>
                                </GoogleMap>
                                <br />
                                <Text as={"b"}>Ubicación:</Text>
                                <Text>2da Avenida Norte, sobre Ruta Militar, San Miguel</Text>
                                <br />
                                <Text as={"b"}>Horario:</Text>
                                <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                <Text>Sábado de 8:00 am a 12:00 md</Text>
                                <br />
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>7858 1276</Text>
                            </ModalApp>
                        </CardBody>
                    </Card>
                </Flex>
            </LoadScript>
        </Layout>
    )

}

export default Agencias