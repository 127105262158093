import React from 'react'
import { connect } from 'react-redux'
import { logout } from '../actions'
import { getAuth, getConfig, getClient } from '../reducers'
import { Box, Flex, Stack } from '@chakra-ui/react'
import Navbar from '../containers/Navbar'
import yt from '../multimedia/icons/yt.png'
import ig from '../multimedia/icons/ig.png'
import tt from '../multimedia/icons/tt.png'
import fb from '../multimedia/icons/fb.png'
import SocialButton from '../components/SocialButton'

import Footer from '../components/Footer'

const Layout = (props) => {

    
    const { children, config, isAuth, client } = props
    return (
        <Box minH={'100vh'} bg={"white"}>
            <Flex justifyContent={"start"} p={"10px"} w={"full"} bg={"easycar2.500"} >
                <Stack direction={'row'} spacing={6}>
                    <SocialButton src={yt} alt={'YouTube'} href={config.youtube || '#'} />
                    <SocialButton src={ig} alt={'Instagram'} href={config.instagram || '#'} />
                    <SocialButton src={fb} alt={'Facebook'} href={config.facebook || '#'} />
                    <SocialButton src={tt} alt={'TikTok'} href={config.tiktok || '#'} />
                </Stack>
            </Flex>
            <Navbar client={client} isAuth={isAuth} logout={props.logout} />
            {children}
            <Footer config={config} />
        </Box>
    )
}

const mapStateToProps = (state) => ({
    client: getClient(state, getAuth(state)),
    isAuth: getAuth(state) === undefined ? false : true,
    config: getConfig(state)
})

const mapDispatchToProps = ({ logout })

export default connect(mapStateToProps, mapDispatchToProps)(Layout)