import React from "react"
import HeroCarousel from "../components/HeroCarousel"
import CarouselMulti from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import SideCar from "../components/SideCar"
import ModalApp from "../containers/Modal"
import { Flex, Box, Heading, Text, Button, Image, Card, CardBody, Icon } from '@chakra-ui/react'
import Layout from '../layout'
import card1 from '../multimedia/slides-cards/0.jpg'
import card2 from '../multimedia/slides-cards/1.jpg'
import card3 from '../multimedia/slides-cards/2.jpg'
import card4 from '../multimedia/slides-cards/3.jpg'
import card5 from '../multimedia/slides-cards/4.jpg'
import card6 from '../multimedia/slides-cards/5.jpg'
import agencia1 from '../multimedia/imagenes/escalon.jpg'
import agencia2 from '../multimedia/imagenes/santaana.jpg'
import agencia3 from '../multimedia/imagenes/sanmiguel.jpg'
import vehiculo from '../multimedia/imagenes/vehiculo2.png'
import call from '../multimedia/imagenes/call2.jpg'
import { Link } from "react-router-dom"
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'


const responsive = {
    largeDesktop: {
        breakpoint: { max: 3000, min: 1400 },
        items: 4,
        partialVisibilityGutter: 30
    },
    desktop: {
        breakpoint: { max: 1400, min: 1024 },
        items: 3,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        partialVisibilityGutter: 30
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 30
    }
};

const CustomRightArrow = ({ onClick, ...rest }) => {
    return <Button onClick={() => onClick()} style={{
        position: "absolute", outline: 0, transition: "all .5s", zIndex: 1000, opacity: 1, cursor: "pointer", border: 0, background: "rgba(0,0,0,0.7)", right: "0", height: "100%"
    }}>
        <Icon as={ChevronRightIcon} color={"white"} boxSize={8} />
    </Button>
}

const CustomLeftArrow = ({ onClick, ...rest }) => {
    return <Button onClick={() => onClick()} style={{
        position: "absolute", outline: 0, transition: "all .5s", zIndex: 1000, opacity: 1, cursor: "pointer", border: 0, background: "rgba(0,0,0,0.7)", left: "0", height: "100%"
    }}>
        <Icon as={ChevronLeftIcon} color={"white"} boxSize={8} />
    </Button>
}

const Landing = () => {

    return (
        <Layout>
            <HeroCarousel />

            <Box maxW={"1400px"} margin={"auto"} mb={"30px"} mt={"2rem"} p={5}>
                <Heading textAlign={"center"}>¿Por qué comprar un vehículo con nosotros?</Heading>
                <br /><br />
                <CarouselMulti showDots={false} infinite={false} responsive={responsive} partialVisible={true} customRightArrow={<CustomRightArrow />} customLeftArrow={<CustomLeftArrow />} >
                    <SideCar
                        src={card1}
                        title={"Te damos 3 meses de garantía"}
                        content={"Consulta las condiciones para esta cobertura."}
                    />

                    <SideCar
                        src={card2}
                        title={"Nuestros vehículos cuentan con una inspección certificada"}
                        content={"Revisamos nuestros vehículos para asegurarnos que todo se encuentre en orden."}
                    />

                    <SideCar
                        src={card3}
                        title={"Contamos con variedad de vehículos"}
                        content={"Conoce nuestras diferentes marcas y modelos de vehículos para que elijas lo que siempre soñaste."}
                    />
                    <SideCar
                        src={card4}
                        title={"Ofrecemos canones accesibles"}
                        content={"Precalificamos en 10 minutos."}
                    />

                    <SideCar
                        src={card5}
                        title={"Entregamos a domicilio"}
                        content={"Te entregamos tu vehículo a la puerta de tu casa o lugar de trabajo. (Consulta condiciones)"}
                    />
                    <SideCar
                        src={card6}
                        title={"Nuestros vehículos como nuevos"}
                        content={"Acondicionamos y preparamos nuestros vehículos en una red de talleres especializados."}
                    />
                </CarouselMulti>
                <br />
                <Box textAlign={"center"}>
                    <Link to={"/cars"} onClick={() => window.scrollTo(0, 0)}>
                        <Button colorScheme={"easycar2"} >Compra tu vehículo</Button>
                    </Link>
                </Box>
            </Box>

            <Box w={"full"} mt={"7.5rem"}>
                <Flex flexDir={{ base: 'column', lg: 'row' }} >
                    <Box maxWidth={{ base: '100%', lg: "50%" }} flex={1} display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"auto"}>
                        <Image alt={'Vehiculos easycar'} height={"auto"} maxWidth={"600px"} src={vehiculo} />
                    </Box>
                    <Box maxWidth={{ base: '100%', lg: "50%" }} bg={"easycar1.500"} flex={1} p={"16"} color={"white"} >
                        <Box maxWidth={"600px"}>
                            <Text fontSize={"3xl"}>Piensa <b>verde</b>,</Text>
                            <Heading>Piensa EASYCAR</Heading>
                            <br />
                            <Text>
                                La economía circular busca reducir el desperdicio y aprovechar al máximo los recursos disponibles. En EASYCAR, contribuimos a este modelo al fomentar la reutilización y conservación de la vida útil de los vehículos.
                            </Text>
                            <Heading>
                                RENOVAMOS Y RESTAURAMOS
                            </Heading>
                            <Text>
                                Reparamos y restauramos con nuestro taller integral nuestros vehículos semi nuevos. Impulsando y apoyando a la economía local y reducimos el desperdicio.
                            </Text>
                            <Heading>
                                ALARGAMOS LA VIDA ÚTIL
                            </Heading>
                            <Text>
                                Con la comercialización de vehículos semi nuevos reducimos la demanda de nuevos recursos naturales.
                            </Text>
                        </Box>
                    </Box>
                </Flex>
            </Box>

            <Box w={"full"} mt={"7.5rem"}>
                <Flex maxW={"1400px"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"}>
                    <Box flex={1} alignItems={"center"} justifyContent={"center"} margin={"auto"}>
                        <Image alt={'Call Center'} height={"auto"} width={{ base: '100%', md: "100%" }} src={call} />
                    </Box>
                    <Box flex={1} p={"10"} margin={"auto"} >
                        <Heading>Aprobamos tu plan de pago con la mejor asesoría</Heading>
                        <br />
                        <Text>Contamos con asesores personalizados que te acompañaran en todo el proceso</Text>
                        <br />
                        <Link to={"/requisitos"} onClick={() => window.scrollTo(0, 0)}>
                            <Button colorScheme="easycar2">Compra tu vehículo en cuotas</Button>
                        </Link>
                    </Box>
                </Flex>
            </Box>

            <Box bg={'gray.100'} w={"full"} p={5} mt={"7.5rem"}>
                <Heading textAlign={"center"}>¡Visita nuestras sedes y conócenos!</Heading>
                <LoadScript googleMapsApiKey="AIzaSyCggvraT4pGMXL0J4wLlfp_b5yoViJ95EY" >
                    <Flex flexDir={{ base: 'column', lg: 'row' }} justifyContent={"space-around"} alignItems={"center"} p={"6"} mt={"10px"} gap={"10px"}>

                        <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                            <Image src={agencia1} h={"300px"} />
                            <CardBody>
                                <Text as={"b"}>Escalón</Text>
                                <Text> 89 Av Norte y 1era Calle Poniente Plaza 89,<br /> Local 4. Colonia Escalón, San Salvador. </Text>
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>2535-5976 y 7567-3475</Text>
                                <ModalApp title={"Easycar Escalón"} >

                                    <GoogleMap
                                        zoom={16}
                                        mapContainerStyle={{ width: '100%', height: '500px' }}
                                        center={{ lat: 13.70410605023694, lng: -89.24176707116466 }}
                                    >
                                        <Marker position={{ lat: 13.70410605023694, lng: -89.24176707116466 }} ></Marker>
                                    </GoogleMap>
                                    <br />
                                    <Text as={"b"}>Ubicación:</Text>
                                    <Text>89 Av Norte y 1era Calle Poniente Plaza 89,<br /> Local 4. Colonia Escalón, San Salvador.</Text>
                                    <br />
                                    <Text as={"b"}>Horario:</Text>
                                    <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                    <Text>Sábado de 8:00 am a 12:00 md</Text>
                                    <br />
                                    <Text as={"b"}>Télefonos:</Text>
                                    <Text>2535-5976 y 7567-3475</Text>
                                </ModalApp>
                            </CardBody>
                        </Card>

                        <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                            <Image src={agencia2} h={"300px"} />
                            <CardBody>
                                <Text as={"b"}>Santa Ana</Text>
                                <Text>Centro Comercial Plaza Bolcaña, Local6,<br />Av. Fray Felipe de Jesús Sur, Santa Ana.</Text>
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>2535-5977 y 7734-6872</Text>
                                <ModalApp title={"Easycar Santa Ana"} >

                                    <GoogleMap
                                        zoom={16}
                                        mapContainerStyle={{ width: '100%', height: '500px' }}
                                        center={{ lat: 13.978647416737255, lng: -89.56907257116468 }}
                                    >
                                        <Marker position={{ lat: 13.978647416737255, lng: -89.56907257116468 }} ></Marker>
                                    </GoogleMap>
                                    <br />
                                    <Text as={"b"}>Ubicación:</Text>
                                    <Text>Centro Comercial Plaza Bolcaña, Local6,<br />Av. Fray Felipe de Jesús Sur, Santa Ana.</Text>
                                    <br />
                                    <Text as={"b"}>Horario:</Text>
                                    <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                    <Text>Sábado de 8:00 am a 12:00 md</Text>
                                    <br />
                                    <Text as={"b"}>Télefonos:</Text>
                                    <Text>2535-5977 y 7734-6872</Text>
                                </ModalApp>
                            </CardBody>
                        </Card>

                        <Card direction={"column"} alignItems={"center"} width={"100%"} minW={"300px"} maxW={"500px"} minHeight={"500px"} mt={"10px"}>
                            <Image src={agencia3} h={"300px"} />
                            <CardBody>
                                <Text as={"b"}>San Miguel</Text>
                                <Text>2da Avenida Norte, sobre Ruta Militar, San Miguel</Text>
                                <Text as={"b"}>Télefonos:</Text>
                                <Text>7858 1276</Text>
                                <ModalApp title={"Easycar San Miguel"} > ,

                                    <GoogleMap
                                        zoom={16}
                                        mapContainerStyle={{ width: '100%', height: '500px' }}
                                        center={{ lat: 13.49279287069646, lng: -88.17746554232934 }}
                                    >
                                        <Marker position={{ lat: 13.49279287069646, lng: -88.17746554232934 }} ></Marker>
                                    </GoogleMap>
                                    <br />
                                    <Text as={"b"}>Ubicación:</Text>
                                    <Text>2da Avenida Norte, sobre Ruta Militar, San Miguel</Text>
                                    <br />
                                    <Text as={"b"}>Horario:</Text>
                                    <Text>Lunes a viernes de 8:00 am a 5:00 pm</Text>
                                    <Text>Sábado de 8:00 am a 12:00 md</Text>
                                    <br />
                                    <Text as={"b"}>Télefonos:</Text>
                                    <Text>7858 1276</Text>
                                </ModalApp>
                            </CardBody>
                        </Card>
                    </Flex>
                </LoadScript>
                <Box textAlign={"center"}>
                    <Link to={"/agencias"} onClick={() => window.scrollTo(0, 0)}>
                        <Button colorScheme="easycar2">Conoce nuestras sedes</Button>
                    </Link>
                </Box>
            </Box>

            <Box textAlign={"center"} w={"full"} mt={"7.5rem"} mb={"2rem"}>
                <Heading>Lo que opinan nuestros clientes</Heading>
            </Box>
            <Box display={"flex"} flexDir={"column"} alignItems={"center"} justifyContent={"center"} w={"full"} textAlign={"center"} mb={"2rem"} >
                <iframe
                    className={"video"}
                    src="https://www.youtube.com/embed/jROIGhsVhWk"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                    title="experiencia Easycar"
                    allowFullScreen></iframe>

                <Link to={"/experiencia-easycar"} onClick={() => window.scrollTo(0, 0)}>
                    <Button colorScheme="easycar2" mt={"1rem"}>Ver más historias de clientes</Button>
                </Link>
            </Box>
        </Layout>
    )
}

export default Landing