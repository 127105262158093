import React from "react"
import { connect } from 'react-redux'
import { updateClient, updateClientPassword, fetchEntityWithId } from '../actions'
import {
    getClient,
    getAuth,
    getEntity
} from '../reducers'
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    Heading,
    CircularProgress,
    Select,
    Textarea,
    HStack,
    Flex
} from '@chakra-ui/react'
import { Formik, useFormikContext } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'


const Municipio = (props) => {
    const { values: { departamento }, touched, setFieldValue } = useFormikContext();
    const { fetchEntityWithId } = props

    React.useEffect(() => {
        if (departamento !== "") fetchEntityWithId('municipio', departamento)
    }, [departamento, touched.departamento, setFieldValue, fetchEntityWithId])

    return <div>{props.children}</div>
};

const Profile = (props) => {

    const { client, updateClient, fetchEntityWithId, updateClientPassword, departamentos, municipios } = props

    return (
        <Layout>
            <Flex flexDirection={"column"} alignItems={"center"} w={'100%'} mt={6} mb={6}>
                <Box rounded={'lg'} bg={"white"} boxShadow={'lg'} p={8} w={"full"} maxW={"900px"}>
                    <Heading>Datos personales </Heading>
                    <Formik
                        initialValues={{
                            nombres: client.cNombres,
                            nombres2: client.cNombres2 || "",
                            apellidos: client.cApellidoPaterno || "",
                            apellidos2: client.cApellidoMaterno || "",
                            documento: client.cDocumento || "",
                            telefono: client.cTelefono,
                            email: client.cEmail,
                            fecha_nacimiento: client.dFechaNacimiento ? new Date(client.dFechaNacimiento).toISOString().split('T')[0] : "",
                            genero: client.cGenero || '',
                            zona_residencial: client.cZonaResidencial || "",
                            departamento: client.cDepartamento || "",
                            municipio: client.cMunicipio || "",
                            direccion: client.cDireccion || "",
                            ingresos: client.nIngresosMensuales || 0,
                            gastos: client.nGastosMensuales || 0,
                            montoPrima: client.nMontoPrima || 0,
                            montoSolicitado: client.nMontoSolicitado || 0,
                            lugar_trabajo: client.cLugarTrabajo || '',
                            ocupacion: client.cOcupacion || "",
                            profesion: client.cProfesion || ""
                        }}
                        validate={values => FormValidator([
                            { field: 'nombres', value: values.nombres, rules: ['required'], lang: 'Primer Nombre' },
                            { field: 'apellidos', value: values.apellidos, rules: ['required'], lang: 'Apellido Materno' },
                            { field: 'documento', value: values.documento, rules: ['required'] },
                            { field: 'telefono', value: values.telefono, rules: ['required'] },
                            { field: 'fecha_nacimiento', value: values.fecha_nacimiento, rules: ['required'] },
                            { field: 'genero', value: values.genero, rules: ['required'] },
                            { field: 'zona_residencial', value: values.zona_residencial, rules: ['required'] },
                            { field: 'departamento', value: values.departamento, rules: ['required'] },
                            { field: 'municipio', value: values.municipio, rules: ['required'] },
                            { field: 'direccion', value: values.direccion, rules: ['required'] },
                            { field: 'ingresos', value: values.ingresos, rules: ['required'] },
                            { field: 'gastos', value: values.gastos, rules: ['required'] },
                            { field: 'montoPrima', value: values.montoPrima, rules: ['required'] },
                            { field: 'montoSolicitado', value: values.montoSolicitado, rules: ['required'] },
                            { field: 'profesion', value: values.profesion, rules: ['required'] },
                            { field: 'ocupacion', value: values.ocupacion, rules: ['required'] },
                            { field: 'lugar_trabajo', value: values.lugar_trabajo, rules: ['required'] },
                        ])}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true)
                            Promise.resolve(updateClient({ ...values, id: client.id }))
                                .then(setSubmitting(false))
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                <HStack>
                                    <FormControl isInvalid={errors.nombres && touched.nombres}>
                                        <FormLabel>Primer Nombre</FormLabel>
                                        <Input type="text" name='nombres' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.nombres} />
                                        <FormErrorMessage>{errors.nombres}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.nombres2 && touched.nombres2}>
                                        <FormLabel>Segundo Nombre</FormLabel>
                                        <Input type="text" name='nombres2' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.nombres2} />
                                        <FormErrorMessage>{errors.nombres2}</FormErrorMessage>
                                    </FormControl>
                                </HStack>
                                <HStack>
                                    <FormControl isInvalid={errors.apellidos && touched.apellidos}>
                                        <FormLabel>Apellido Paterno</FormLabel>
                                        <Input type={'text'} size="lg" name={"apellidos"} onChange={handleChange} onBlur={handleBlur} value={values.apellidos} />
                                        <FormErrorMessage>{errors.apellidos}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.apellidos2 && touched.apellidos2}>
                                        <FormLabel>Apellido Materno</FormLabel>
                                        <Input type={'text'} size="lg" name={"apellidos2"} onChange={handleChange} onBlur={handleBlur} value={values.apellidos2} />
                                        <FormErrorMessage>{errors.apellidos2}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <HStack>
                                    <FormControl isInvalid={errors.documento && touched.documento}>
                                        <FormLabel>Documento de identidad</FormLabel>
                                        <Input type={'text'} size="lg" name={"documento"} onChange={handleChange} onBlur={handleBlur} value={values.documento} />
                                        <FormErrorMessage>{errors.documento}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.telefono && touched.telefono}>
                                        <FormLabel>Teléfono</FormLabel>
                                        <Input type={'number'} size="lg" name={"telefono"} onChange={handleChange} onBlur={handleBlur} value={values.telefono} />
                                        <FormErrorMessage>{errors.telefono}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <FormControl isInvalid={errors.email && touched.email}>
                                    <FormLabel>Correo</FormLabel>
                                    <Input type={'email'} size="lg" onChange={handleChange} onBlur={handleBlur} value={values.email} readOnly />
                                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                                </FormControl>
                                <HStack>

                                    <FormControl isInvalid={errors.fecha_nacimiento && touched.fecha_nacimiento}>
                                        <FormLabel>Fecha de nacimiento</FormLabel>
                                        <Input type={'date'} size="lg" name={"fecha_nacimiento"} onChange={handleChange} onBlur={handleBlur} value={values.fecha_nacimiento} max="2999-12-31" min={"1900-01-01"} />
                                        <FormErrorMessage>{errors.fecha_nacimiento}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={errors.genero && touched.genero}>
                                        <FormLabel>Género</FormLabel>
                                        <Select size="lg" name={"genero"} onChange={handleChange} onBlur={handleBlur} value={values.genero} >
                                            <option value={""}>Seleccione género</option>
                                            <option value={"No mencionado"}>No mencionado</option>
                                            <option value={"Masculino"}> Masculino</option>
                                            <option value={"Femenino"}> Femenino</option>
                                        </Select>
                                        <FormErrorMessage>{errors.genero}</FormErrorMessage>
                                    </FormControl>
                                </HStack>



                                <FormControl isInvalid={errors.zona_residencial && touched.zona_residencial}>
                                    <FormLabel>Zona residencial</FormLabel>
                                    <Select size="lg" name={"zona_residencial"} onChange={handleChange} onBlur={handleBlur} value={values.zona_residencial} >
                                        <option value={""}>Seleccione zona</option>
                                        <option value={"Zona central"}>Zona central</option>
                                        <option value={"Zona oriental"}>Zona oriental</option>
                                        <option value={"Zona occidental"}>Zona occidental</option>
                                    </Select>
                                    <FormErrorMessage>{errors.zona_residencial}</FormErrorMessage>
                                </FormControl>

                                <HStack>
                                    <FormControl isInvalid={errors.departamento && touched.departamento}>
                                        <FormLabel>Departamento</FormLabel>
                                        <Select size="lg" name={"departamento"} onChange={handleChange} onBlur={handleBlur} value={values.departamento} >
                                            <option value={""}>Seleccione departamento</option>
                                            {departamentos.filter(departamento => {
                                                if (values.zona_residencial === departamento.ZonaResidencial) return true
                                                return false
                                            }
                                            ).map(departamento =>
                                                <option value={departamento.CodDepartamento} key={departamento.CodDepartamento}> {departamento.Departamento} </option>
                                            )}
                                        </Select>
                                        <FormErrorMessage>{errors.departamento}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.municipio && touched.municipio}>
                                        <Municipio departamentosList={departamentos} fetchEntityWithId={fetchEntityWithId}>
                                            <FormLabel>Municipio</FormLabel>
                                            <Select size="lg" name={"municipio"} onChange={handleChange} onBlur={handleBlur} value={values.municipio}  >
                                                <option value={""}>Seleccione municipio</option>
                                                {municipios.map(municipio =>
                                                    <option value={municipio.CodMunicipio} key={municipio.CodMunicipio}> {municipio.Municipio} </option>
                                                )}
                                            </Select>
                                            <FormErrorMessage>{errors.municipio}</FormErrorMessage>
                                        </Municipio>
                                    </FormControl>
                                </HStack>
                                <FormControl isInvalid={errors.direccion && touched.direccion}>
                                    <FormLabel>Dirección</FormLabel>
                                    <Textarea size="lg" name={"direccion"} onChange={handleChange} onBlur={handleBlur} value={values.direccion} />
                                    <FormErrorMessage>{errors.direccion}</FormErrorMessage>
                                </FormControl>

                                <HStack>
                                    <FormControl isInvalid={errors.ingresos && touched.ingresos}>
                                        <FormLabel>Ingresos mensuales</FormLabel>
                                        <Input type={'number'} size="lg" name={"ingresos"} onChange={handleChange} onBlur={handleBlur} value={values.ingresos} />
                                        <FormErrorMessage>{errors.ingresos}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={errors.gastos && touched.gastos}>
                                        <FormLabel>Gastos mensuales</FormLabel>
                                        <Input type={'number'} size="lg" name={"gastos"} onChange={handleChange} onBlur={handleBlur} value={values.gastos} />
                                        <FormErrorMessage>{errors.gastos}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <HStack>
                                    <FormControl isInvalid={errors.lugar_trabajo && touched.lugar_trabajo}>
                                        <FormLabel>Lugar de trabajo</FormLabel>
                                        <Input type={'text'} size="lg" name={"lugar_trabajo"} onChange={handleChange} onBlur={handleBlur} value={values.lugar_trabajo} />
                                        <FormErrorMessage>{errors.lugar_trabajo}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={errors.profesion && touched.profesion}>
                                        <FormLabel>Profesión</FormLabel>
                                        <Select size="lg" name={"profesion"} onChange={handleChange} onBlur={handleBlur} value={values.profesion} >
                                            <option value={""}>Seleccione Profesión</option>
                                            <option value={"EMPLEADO DEL SECTOR PRIVADO"}>EMPLEADO DEL SECTOR PRIVADO</option>
                                            <option value={"EMPLEADO DEL SECTOR PÚBLICO"}>EMPLEADO DEL SECTOR PÚBLICO</option>
                                            <option value={"EMPLEADOS CON UN CONTRATO TEMPORAL"}>EMPLEADOS CON UN CONTRATO TEMPORAL</option>
                                            <option value={"COMERCIANTE INDIVIDUAL CONSTITUIDO"}>COMERCIANTE INDIVIDUAL CONSTITUIDO</option>
                                            <option value={"CONTRATISTAS /TÉCNICOS INDEPENDIENTES"}>CONTRATISTAS /TÉCNICOS INDEPENDIENTES</option>
                                            <option value={"PROFESIONALES INDEPENDIENTES"}>PROFESIONALES INDEPENDIENTES</option>
                                            <option value={"COMERCIANTE INFORMAL"}>COMERCIANTE INFORMAL</option>
                                            <option value={"BENEFICIARIO REMESAS"}>BENEFICIARIO REMESAS</option>
                                            <option value={"JUBILADOS & PENSIONADOS"}>JUBILADOS & PENSIONADOS</option>
                                            <option value={"ARRENDAMIENTO"}>ARRENDAMIENTO</option>
                                        </Select>
                                        <FormErrorMessage>{errors.profesion}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <FormControl isInvalid={errors.ocupacion && touched.ocupacion}>
                                    <FormLabel>Ocupación</FormLabel>
                                    <Input type={'text'} size="lg" name={"ocupacion"} onChange={handleChange} onBlur={handleBlur} value={values.ocupacion} />
                                    <FormErrorMessage>{errors.ocupacion}</FormErrorMessage>
                                </FormControl>

                                <HStack>
                                    <FormControl isInvalid={errors.montoPrima && touched.montoPrima}>
                                        <FormLabel>Monto de prima</FormLabel>
                                        <Input type={'number'} size="lg" name={"montoPrima"} onChange={handleChange} onBlur={handleBlur} value={values.montoPrima} />
                                        <FormErrorMessage>{errors.montoPrima}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={errors.montoSolicitado && touched.montoSolicitado}>
                                        <FormLabel>Monto solicitado</FormLabel>
                                        <Input type={'number'} size="lg" name={"montoSolicitado"} onChange={handleChange} onBlur={handleBlur} value={values.montoSolicitado} />
                                        <FormErrorMessage>{errors.montoSolicitado}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                    {isSubmitting ? <CircularProgress /> : 'Actualizar'}
                                </Button>
                            </form>
                        )}
                    </Formik>
                </Box>

                <Box rounded={'lg'} bg={"white"} boxShadow={'lg'} p={8} w={"full"} maxW={"900px"} mt={6}>
                    <Heading>Contraseña</Heading>
                    <Formik
                        initialValues={{ password: '', oldpassword: '', repeatpassword: '' }}
                        validate={values => FormValidator([
                            { field: 'password', value: values.password, rules: ['required'], lang: 'Contraseña' },
                            { field: 'repeatpassword', value: values.repeatpassword, rules: ['required', 'same'], param: values.password, lang: 'repetir contraseña' },
                            { field: 'oldpassword', value: values.oldpassword, rules: ['required'], lang: 'Contraseña actual' }
                        ])}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true)
                            Promise.resolve(updateClientPassword({ ...values, id: client.id, email: client.cEmail }, resetForm))
                                .then(() => setSubmitting(false))
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                <FormControl isInvalid={errors.oldpassword && touched.oldpassword}>
                                    <FormLabel>Contraseña actual</FormLabel>
                                    <Input type="password" name='oldpassword' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.oldpassword} />
                                    <FormErrorMessage>{errors.oldpassword}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.password && touched.password}>
                                    <FormLabel>Nueva contraseña</FormLabel>
                                    <Input type={'password'} size="lg" name={"password"} onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.repeatpassword && touched.repeatpassword}>
                                    <FormLabel>Repetir Contraseña</FormLabel>
                                    <Input type="password" name='repeatpassword' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.repeatpassword} />
                                    <FormErrorMessage>{errors.repeatpassword}</FormErrorMessage>
                                </FormControl>
                                <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                    {isSubmitting ? <CircularProgress /> : 'Actualizar'}
                                </Button>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Flex>
        </Layout>
    )
}

const mapStateToProps = (state) => ({
    client: getClient(state, getAuth(state)),
    departamentos: getEntity(state, 'departamento'),
    municipios: getEntity(state, 'municipio')
})

const mapDispatchToProps = { updateClient, updateClientPassword, fetchEntityWithId }

export default connect(mapStateToProps, mapDispatchToProps)(Profile)