import React from "react"
import { connect } from 'react-redux'
import { updateSolicitud } from "../actions"
import {
    getSolicitud
} from '../reducers'
import Layout from '../layout'
import {
    Flex,
    Box,
    HStack,
    Input, Select,
    FormLabel,
    FormControl,
    CircularProgress,
    Heading,
    FormErrorMessage,
    Button,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'

class FinanciamientoDetailStatus extends React.Component {


    tiempo_espera = (inicio, fin) => {
        let inicioDate = new Date(inicio)
        let finDate = new Date(fin)
        let time = Math.abs(finDate - inicioDate)
        var ms = time % 1000
        time = (time - ms) / 1000
        var secs = time % 60
        time = (time - secs) / 60
        var mins = time % 60
        var hrs = (time - mins) / 60

        return hrs + ':' + mins + ':' + secs
    }


    render() {
        const { solicitud } = this.props

        return (
            <Layout>
                <Flex flexDirection={"column"} alignItems={"center"} w={'100%'} mt={"3.5rem"} mb={"3.5rem"}>
                    <Box bg={'white'} rounded={'md'} m={3} p={6} w={"full"} maxW={"900px"}>
                        <Heading textAlign={"center"}>
                            Tu solicitud se encuentra en Resultado: {solicitud.cEstadoFinanciamiento === 4 ? 'Pre aprobado' : 'En proceso'}
                        </Heading>
                        <br /><br />

                        <FormControl>
                            <FormLabel>Nombre del cliente: </FormLabel>
                            <Input readOnly variant='flushed' value={`${solicitud.cNombres} ${solicitud.cNombres2} ${solicitud.cApellidoPaterno} ${solicitud.cApellidoMaterno}`} />
                        </FormControl>
                        <HStack>
                            <FormControl>
                                <FormLabel>Fecha de solicitud: </FormLabel>
                                <Input readOnly variant='flushed' value={new Date(solicitud.dFechaCreacion).toISOString().split('T')[0]} />
                            </FormControl>
                            {solicitud.dFechaAprobacion !== null &&
                                <FormControl>
                                    <FormLabel>Fecha de aprobación: </FormLabel>
                                    <Input readOnly variant='flushed' value={new Date(solicitud.dFechaAprobacion).toISOString().split('T')[0]} />
                                </FormControl>
                            }
                        </HStack>

                        {solicitud.nCarro === 0 ? undefined :
                            <Box>
                                <HStack>
                                    <FormControl>
                                        <FormLabel>Tipo Vehiculo: </FormLabel>
                                        <Input readOnly variant='flushed' value={solicitud.cClase} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Marca: </FormLabel>
                                        <Input readOnly variant='flushed' value={solicitud.cMarca} />
                                    </FormControl>
                                </HStack>
                                <HStack>
                                    <FormControl>
                                        <FormLabel>Valor: </FormLabel>
                                        <Input readOnly variant='flushed' value={'$' + solicitud.nValor} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Prima: </FormLabel>
                                        <Input readOnly variant='flushed' value={'$' + solicitud.nPrima} />
                                    </FormControl>
                                </HStack>
                            </Box>
                        }


                        {solicitud.dFechaAprobacion !== null &&
                            <div>
                                <HStack>
                                    <FormControl >
                                        <FormLabel>Monto Otorgado</FormLabel>
                                        <Input readOnly variant='flushed' value={'$' + solicitud.nMontoOtorgado.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2})} />
                                    </FormControl>
                                    <FormControl >
                                        <FormLabel>Tasa Aprobada</FormLabel>
                                        <Input readOnly variant='flushed' value={solicitud.nTasaAprobada} />
                                    </FormControl>
                                </HStack>
                                <HStack>
                                    <FormControl >
                                        <FormLabel>Plazo</FormLabel>
                                        <Input readOnly variant='flushed' value={solicitud.cPlazo} />
                                    </FormControl>
                                    <FormControl >
                                        <FormLabel>Cuota</FormLabel>
                                        <Input readOnly variant='flushed' value={'$' +solicitud.nCuota.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2})} />
                                    </FormControl>
                                </HStack>
                            </div>
                        }
                        {(solicitud.dFechaAprobacion !== null && solicitud.cDecision === null) &&

                            <Formik
                                initialValues={{
                                    decision: solicitud.cDecision || ''
                                }}
                                validate={values => FormValidator([
                                    { field: 'decision', value: values.decision, rules: ['required'] }
                                ])}
                                onSubmit={(values, { setSubmitting }) => {
                                    Promise.resolve(this.props.updateSolicitud({ ...values, id: solicitud.id }))
                                        .then(() => { setSubmitting(false) })
                                }}
                            >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                    <form onSubmit={handleSubmit}>
                                        <FormControl isInvalid={errors.decision && touched.decision}>
                                            <FormLabel>Su plan ha sido pre-aprobado: </FormLabel>
                                            <Select name="decision" onChange={handleChange} onBlur={handleBlur} value={values.decision}>
                                                <option value={""}>Seleccione la opción adecuada a su decisión</option>
                                                <option value={"Aceptación del crédito con las condiciones establecidas"}>Aceptación del crédito con las condiciones establecidas</option>
                                                <option value={"Solicito mejora de tasa"}>Solicito mejora de tasa</option>
                                                <option value={"Solicito mejora de plazo"}>Solicito mejora de plazo</option>
                                                <option value={"Solicito monto mayor"}>Solicito monto mayor</option>
                                            </Select>
                                            <FormErrorMessage>{errors.decision}</FormErrorMessage>
                                        </FormControl>
                                        <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                            {isSubmitting ? <CircularProgress /> : 'Guardar'}
                                        </Button>
                                    </form>
                                )}
                            </Formik>
                        }
                    </Box>
                </Flex>
            </Layout>
        )
    }

}

const mapStateToProps = (state, ownProps) => ({
    solicitud: getSolicitud(state, ownProps.match.params.id)
})


export default connect(mapStateToProps, { updateSolicitud })(FinanciamientoDetailStatus)