import React from "react"
import {
    Box, Flex, Image, Text, Heading, UnorderedList, ListItem,
    Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon
} from '@chakra-ui/react'
import image1 from '../multimedia/imagenes/faq.jpg'
import Layout from '../layout'


const Faq = (props) => {

    return (
        <Layout>
            <Flex maxW={"1400px"} margin={"auto"} bg={"white"} flexDir={{ base: 'column', md: 'row' }}>
                <Box order={{ base: 2, md: 1 }} className={'sideLeft'} w={"full"} maxW={{ base: "full", md: "40%" }} pl={"5%"} m={"auto"} >
                    <Box flex={1} p={"10"} margin={"auto"} >
                        <Heading>¿En qué podemos ayudarte?</Heading>
                    </Box>
                </Box>
                <Box order={{ base: 1, md: 2 }} className={'sideRight'} w={"full"} maxW={{ base: "full", md: "60%" }}>
                    <Image alt={'Conduciendo'} objectFit='cover' src={image1} />
                </Box>
            </Flex>

            <Box w={"full"}  >
                <Flex maxW={"900px"} flexDir={"column"} p={4} m={"auto"} mt={"3.5rem"} mb={"2rem"}>

                    <Heading textAlign={"center"}>Preguntas Frecuentes</Heading>
                    <Accordion mt={"20px"}>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Qué es un arrendamiento financiero?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Es un alquiler donde el arrendador concede su uso y goce de determinados bienes, muebles e inmuebles, por un plazo en el cual el arrendatario se obliga a pagar un canon y gastos con derecho a compra o devolución al finalizar el contrato.</Text></AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿De cuánto es el plazo máximo del arrendamiento financiero?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Hasta 60 cánones mensuales.</Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Qué tipo y año de vehículo puedo adquirir en arrendamiento financiero con EASYCAR?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Puedes adquirir vehículos desde el año 2009 como tipo sedán, camionetas, Pick Up, vehículos de trabajo y turismo.</Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Cuáles son los requisitos para adquirir un arrendamiento financiero?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text><UnorderedList>
                                <ListItem>DUI</ListItem>
                                <ListItem>NIT</ListItem>
                                <ListItem>Recibo de servicios</ListItem>
                                <ListItem>Comprobante de ingresos</ListItem>
                                <ListItem>Contar con un monto de prima</ListItem>
                            </UnorderedList></Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>Si soy comerciante/emprendedor no contribuyente, ¿puedo optar a un arrendamiento financiero?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Sí, puedes presentar: DUI, recibo de agua o luz, factura/tickets y será sujeto a verificación de negocio.</Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>Si soy transportista en plataformas como Uber, Indrive, PedidosYa o particular, ¿puedo optar a un arrendamiento financiero?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Sí, puedes presentar: DUI, recibo de agua o luz, captura de perfil de plataforma o declaración jurada de ingresos.</Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Puedo aplicar si recibo ingresos del extranjero?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Sí, puedes aplicar mediante: remesas, estado de cuenta bancaria, comprobante de pensión.</Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Cómo puedo vender mi vehículo con EASYCAR?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text><UnorderedList>
                                <ListItem>Contacta a nuestro asesor.</ListItem>
                                <ListItem>Elige una oferta.</ListItem>
                                <ListItem>Agenda una inspección.</ListItem>
                                <ListItem>Realiza tu venta.</ListItem>
                            </UnorderedList></Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Cómo puedo intercambiar mi vehículo por uno más reciente?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text><UnorderedList>
                                <ListItem>Contacta a un asesor.</ListItem>
                                <ListItem>Elige una oferta.</ListItem>
                                <ListItem>Agenda una inspección.</ListItem>
                                <ListItem>Selecciona tu nuevo vehículo.</ListItem>
                                <ListItem>Precalifica el monto restante.</ListItem>
                            </UnorderedList></Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Para qué me sirve crear una cuenta en EASYCAR WEB?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Al crear una cuenta en nuestro sitio web, puedes dar seguimiento a tu solicitud de arrendamiento financiero.</Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Dónde están ubicadas las agencias EASYCAR?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Disponemos de tres agencias por el momento las cuales son:
                                <UnorderedList>
                                    <ListItem>Agencia Escalón: 89 Av Norte y 1era Calle Poniente Plaza 89, Local 4. Colonia Escalón, San Salvador.</ListItem>
                                    <ListItem>Agencia Santa Ana: Centro Comercial Plaza Bolcaña, Local 6, Av. Fray Felipe de Jesús Sur, Santa Ana.</ListItem>
                                    <ListItem>Agencia San Miguel: 2da Av. Norte sobre Ruta Militar, San Miguel</ListItem>
                                </UnorderedList>
                            </Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Cuáles son los horarios de atención?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Todas nuestras agencias y contact center tienen horario de lunes a viernes de 8:00 a.m. a 5:00 p.m. sin cerrar al medio día y sábado de 8:00 a.m. a 12:00 m.d.</Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Los vehículos de EASYCAR poseen garantía?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Sí, contamos garantía, la cual se aplica según la política vigente.</Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Puedo adquirir un arrendamiento financiero si poseo mal récord crediticio?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Sí, poseer un mal récord no afectará tu proceso.</Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Puedo solicitar un arrendamiento financiero sin tener historial crediticio?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Sí, puedes solicitarnos tu precalificación, aunque no cuentes con historial crediticio.
                                Todos nuestros clientes pueden aplicar para conocer la opción de pago que más les conviene.
                            </Text></AccordionPanel>
                        </AccordionItem>


                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Puedo adquirir mi vehículo al contado con EASYCAR?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Si, contamos con socios aliados que nos permiten facilitar el proceso de compra directa.</Text></AccordionPanel>
                        </AccordionItem>


                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Cuáles son las formas de pago?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>
                                <UnorderedList>
                                    <ListItem>Transferencia.</ListItem>
                                    <ListItem>Pago en ventanilla en nuestras agencias.</ListItem>
                                    <ListItem>Canales autorizados de Punto Express.</ListItem>
                                </UnorderedList>
                            </Text></AccordionPanel>
                        </AccordionItem>


                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Cuánto demora el proceso para adquirir mi vehículo?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Nuestro proceso es rápido, fácil y seguro que durará 24 horas después de haber seleccionado tu vehículo.</Text></AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                </Flex>
            </Box>

        </Layout >
    )
}

export default Faq
