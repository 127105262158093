import { Box, Text, Heading, Flex, Image, Button, Avatar } from '@chakra-ui/react'
import Layout from '../layout'
import image1 from '../multimedia/imagenes/conducir.jpg'
import image2 from '../multimedia/imagenes/call2.jpg'
import Card1 from '../multimedia/icons/icono1.svg'
import Card2 from '../multimedia/icons/icono2.svg'
import Card3 from '../multimedia/icons/icono3.svg'
import Card4 from '../multimedia/icons/icono5.svg'
import Card5 from '../multimedia/icons/icono6.svg'
import MiniCard from "../components/MiniCard"
import { Link } from 'react-router-dom/cjs/react-router-dom'

const Requisitos = () => {

    return (
        <Layout>


            <Flex maxW={"1400px"} margin={"auto"} bg={"#338e40"} color={"white"} flexDir={{ base: 'column', md: 'row' }}>
                <Box order={{ base: 2, md: 1 }} className={'sideLeft'} w={"full"} maxW={{base : "full", md: "40%" } } pl={"5%"} m={"auto"} >
                    <Box flex={1} p={"10"} margin={"auto"} >
                        <Heading>Pregunta por nuestro arrendamiento financiero</Heading>
                        <Text>
                            Conoce como puedes adquirir un vehículo en cuotas
                        </Text>
                        <Link to={`/cotizar`}>
                            <Button colorScheme='easycar2'>Llévatelo a cuotas</Button>
                        </Link>
                    </Box>
                </Box>
                <Box order={{ base: 1, md: 2 }} className={'sideRight'} w={"full"} maxW={{base : "full", md: "60%" } }>
                    <Image alt={'Conduciendo'} objectFit='cover' src={image1} />
                </Box>
            </Flex>

            <Box textAlign={"center"} maxW={"1400px"} margin={"auto"} mt={"7.5rem"} mb={"2rem"}>
                <Heading mt={"20px"}>Pasos para arrendamiento financiero</Heading>
                <Flex flexDir={{ base: 'column', lg: 'row' }} alignItems={"center"} justifyContent={"space-around"} pt={"100px"} pb={"100px"} bg={"white"}>
                    <MiniCard
                        icon={<Avatar className='miniCarIcon' bg={"#f0fdea"} size='xl' icon={<Image src={Card1} height={"50px"} />} />}
                        title={"Precalifica"}
                        content={"De acuerdo a los datos que nos proporciones te brindaremos la precalificación en menos de 10min."}
                    />

                    <MiniCard
                        icon={<Avatar className='miniCarIcon' bg={"#f0fdea"} size='xl' icon={<Image src={Card2} height={"50px"} />} />}
                        title={"Selecciona el vehículo de tus sueños"}
                        content={"Te asesoramos en encontrar el vehículo que más se adapte a tus necesidades."}
                    />

                    <MiniCard
                        icon={<Avatar className='miniCarIcon' bg={"#f0fdea"} size='xl' icon={<Image src={Card3} height={"50px"} />} />}
                        title={"Análisis y aprobación"}
                        content={"Recopilamos tus comprobantes de ingresos, llegamos hasta la puerta de tu negocio o empresa."}
                    />
                    <MiniCard
                        icon={<Avatar className='miniCarIcon' bg={"#f0fdea"} size='xl' icon={<Image src={Card4} height={"50px"} />} />}
                        title={"Paga tu Prima"}
                        content={"Easycar te brinda la facilidad de adquirir tu vehículo con primas accesibles."}
                    />

                    <MiniCard
                        icon={<Avatar className='miniCarIcon' bg={"#f0fdea"} size='xl' icon={<Image src={Card5} height={"50px"} />} />}
                        title={"Llévatelo..."}
                        content={"Y empieza a disfrutar tu experiencia Easycar."}
                    />
                </Flex>
            </Box>

            <Box w={"full"} >
                <Flex bg={'gray.100'} maxW={"1400px"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"} mt={"7.5rem"}>
                    <Box flex={1} alignItems={"center"} justifyContent={"center"} margin={"auto"}>
                        <Image alt={'Call center'} height={"auto"} width={{ base: '100%', md: "80%" }} src={image2} />
                    </Box>
                    <Box flex={1} p={"10"} margin={"auto"} >
                        <Heading>Aprobamos tu plan de pago con la mejor asesoría</Heading>
                        <br />
                        <Text>Contamos con asesores personalizados que te acompañarán en todo el proceso.</Text>
                        <br />
                        <Link to={`/cotizar`}>
                            <Button colorScheme='easycar2'>Compra tu vehículo en cuotas</Button>
                        </Link>
                    </Box>

                </Flex>
            </Box>
        </Layout>
    )

}

export default Requisitos