import {
    Box,
    Text,
    Flex,
    Select,
    Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark
} from '@chakra-ui/react'

const FiltradoCarros = (props) => {

    const { applyFilter, priceFilter, pasajeroFilter, kilometrajeFilter, marcaFilter, values, tipos, marcas, modelos, annos, combustibles, transmisiones, colores, zonas } = props
    return <Flex m={"auto"} alignContent={"center"} flexDir={"column"} gap={2} mt={"20px"}>
        <Select onChange={applyFilter} name={"lugar"} value={values.lugar}>
            <option value={""}>Seleccione zona</option>
            {zonas.map((zona, i) =>
                <option value={zona.cLugar} key={i}>{zona.cLugar.toUpperCase()}</option>
            )}
        </Select>
        <Select onChange={applyFilter} name={"tipo"} value={values.tipo}>
            <option value={""}>Todos los tipos</option>
            {tipos.map((tipo, i) =>
                <option value={tipo.cClase} key={i}>{tipo.cClase.toUpperCase()}</option>
            )}
        </Select>

        <Select onChange={marcaFilter} name={"marca"} value={values.marca}>
            <option value={""}>Todos los marcas</option>
            {marcas.map((marca, i) =>
                <option value={marca.cMarca} key={i}>{marca.cMarca.toUpperCase()}</option>
            )}
        </Select>

        <Select onChange={applyFilter} name={"modelo"} value={values.modelo}>
            <option value={""}>Todos los módelos</option>
            {modelos.map((modelos, i) =>
                <option value={modelos.cModelo} key={i}>{modelos.cModelo.toUpperCase()}</option>
            )}
        </Select>

        <Select onChange={applyFilter} name={"annio"} value={values.annio}>
            <option value={""}>Todos los años</option>
            {annos.map((anno, i) =>
                <option value={anno.nAño} key={i}>{anno.nAño}</option>
            )}
        </Select>

        <br />
        <Text>Precio</Text>
        <Box p={6} width={"80%"} m={"auto"} >
            <Slider aria-label='slider-ex-6' min={0} max={50000} step={1000} onChangeEnd={(val) => priceFilter(val)} defaultValue={values.precio} >
                <SliderMark value={0}>$0</SliderMark>
                <SliderMark value={50000}>$50,000</SliderMark>
                <SliderMark value={values.precio} textAlign='center' mt='-10' ml='-5'>
                    ${values.precio.toLocaleString('en-US')}
                </SliderMark>
                <SliderTrack bg='green.100'>
                    <SliderFilledTrack bg='green' />
                </SliderTrack>
                <SliderThumb boxSize={6} />
            </Slider>
        </Box>

        <Select onChange={applyFilter} name={"color"} value={values.color}>
            <option value={""}>Todos los colores</option>
            {colores.map((color, i) =>
                <option value={color.cColor} key={i}>{color.cColor.toUpperCase()}</option>
            )}
        </Select>

        <Select onChange={applyFilter} name={"transmision"} value={values.transmision}>
            <option value={""}>Todos las transmisiones</option>
            {transmisiones.map((transmision, i) =>
                <option value={transmision.cTransmision} key={i}>{transmision.cTransmision.toUpperCase()}</option>
            )}
        </Select>

        <br />
        <Text>Pasajeros</Text>
        <Box p={6} width={"80%"} m={"auto"}>
            <Slider aria-label='slider-ex-6' min={1} max={16} step={1} onChangeEnd={(val) => pasajeroFilter(val)} defaultValue={values.pasajero} >
                <SliderMark value={1}>1</SliderMark>
                <SliderMark value={16}>16</SliderMark>
                <SliderMark value={values.pasajero} textAlign='center' mt='-10' ml='-5'>
                    {values.pasajero}
                </SliderMark>
                <SliderTrack bg='green.100'>
                    <SliderFilledTrack bg='green' />
                </SliderTrack>
                <SliderThumb boxSize={6} />
            </Slider>
        </Box>

        <br />
        <Text>Recorrido</Text>
        <Box p={6} width={"80%"} m={"auto"}>
            <Slider aria-label='slider-ex-6' min={0} max={320000} step={1000} onChangeEnd={(val) => kilometrajeFilter(val)} defaultValue={values.kilometraje} >
                <SliderMark value={1}>1</SliderMark>
                <SliderMark value={320000}>320,000</SliderMark>
                <SliderMark value={values.kilometraje} textAlign='center' mt='-10' ml='-5'>
                    {values.kilometraje.toLocaleString('en-US')}
                </SliderMark>
                <SliderTrack bg='green.100'>
                    <SliderFilledTrack bg='green' />
                </SliderTrack>
                <SliderThumb boxSize={6} />
            </Slider>
        </Box>

        <Select onChange={applyFilter} name={"combustible"} value={values.combustible}>
            <option value={""}>Todos los combustibles</option>
            {combustibles.map((combustible,i) =>
                <option value={combustible.cCombustible} key={i}>{combustible.cCombustible.toUpperCase()}</option>
            )}
        </Select>
        <br />
    </Flex>
}

export default FiltradoCarros