import React from "react"
import { Box, Text, Heading } from '@chakra-ui/react'
import Layout from '../layout'


const Terminos_condiciones = (props) => {

    return (
        <Layout>


            <Box w={"full"} p={4} m={"auto"} mt={"3.5rem"} mb={"2rem"} maxW={"1400px"} textAlign={"justify"}>
                <Heading>Terminos y condiciones</Heading>
                <br />
                <Text as={"b"}>POLÍTICAS Y CONDICIONES DE USO DE PÁGINA WEB DE INVERSIONES FUTURO, LTDA. DE C.V. </Text>
                <Text> <br/>
                    La PÁGINA WEB de INVERSIONES FUTURO, LTDA. DE C.V. creada para la provisión de servicios y productos que comercializa INVERSIONES FUTURO, LTDA. DE C.V., como una herramienta fácil y segura de utilizar para sus clientes. Provee información de servicios y productos, entre otros servicios. Se han considerado políticas con las que se asegura que la PÁGINA WEB de INVERSIONES FUTURO, LTDA. DE C.V.  cuenta con los estándares de calidad requeridos en cuanto al uso de la información y seguridad de esta. Estableciendo lo siguiente:
                    <br/>1. La información que el usuario introduzca a través de la PÁGINA WEB, será de uso exclusivo de INVERSIONES FUTURO, LTDA. DE C.V.  y para los fines correspondientes de su uso.
                    <br/>2. INVERSIONES FUTURO, LTDA. DE C.V., se compromete a resguardar la información personal (Nombres, número celular, dirección de correo electrónico, ingresos) que el usuario ingrese a través de la PÁGINA WEB.
                    <br/>3. La información que ingresen los usuarios y clientes quedarán debidamente registradas en nuestros servidores.
                    <br/>4. INVERSIONES FUTURO, LTDA. DE C.V., utilizará la información que recopila para desarrollar y mejorar el producto para ofrecer un mejor servicio.
                    <br/>5. El usuario es totalmente responsable de no brindar su información personal o credenciales de autenticación a terceras personas desconocidas para INVERSIONES FUTURO, LTDA. DE C.V., que ponga en riesgo la seguridad y la información contenida en la aplicación.
                    <br/>INVERSIONES FUTURO, LTDA. DE C.V., no comparte información personal con empresas, organizaciones o personas que no forman parte de su grupo empresarial, a menos que se produzca alguna de las siguientes circunstancias:
                    <br/>1.	Por motivos legales.
                    <br/>Compartiremos información personal con empresas, organizaciones e individuos fuera de INVERSIONES FUTURO, LTDA. DE C.V. si creemos de buena fe que el acceso, el uso, la conservación o la divulgación de la información es razonablemente necesaria para:
                    <br/>1. Cumplir con las leyes aplicables, las regulaciones, los procesos legales o las exigencias gubernamentales vigentes.
                    <br/>2. Garantizar el cumplimiento de las condiciones del servicio correspondientes, incluida la investigación de posibles violaciones.
                    <br/>3. Detectar, evitar o de otro modo tratar los problemas técnicos, de fraude o seguridad.
                    <br/>4. Proteger de posibles daños a los derechos, la propiedad o la seguridad de INVERSIONES FUTURO, LTDA. DE C.V., de nuestros usuarios o del público según lo establezca o permita la ley.
                    <br/>COPYRIGHT Y MARCA REGISTRADA
                    <br/>Los contenidos de todo el material disponible en la PÁGINA WEB son propiedad de INVERSIONES FUTURO, LTDA. DE C.V., quien hace expresa reserva de todos sus derechos de propiedad intelectual y/o cualquier otro derecho que le corresponda en relación a esta PÁGINA WEB; por lo que ningún contenido podrá ser reproducido, descargado, difundido o transferido, en cualquier forma o por cualquier medio, excepto en el caso que INVERSIONES FUTURO, LTDA. DE C.V., posea un acuerdo previo que así lo habilite.
                    <br/>TÉRMINOS Y CONDICIONES PÁGINA WEB
                    <br/>El servicio que se presta a través de la PÁGINA WEB, en adelante denominado como el "Sitio", es provisto por INVERSIONES FUTURO, LTDA. DE C.V., mismo que se brinda únicamente a los usuarios y clientes de INVERSIONES FUTURO, LTDA. DE C.V., que acepten los términos y condiciones aquí establecidos y los términos y condiciones establecidos en el contrato respectivo que haya celebrado previamente el usuario del Sitio con INVERSIONES FUTURO, LTDA. DE C.V., (el "Usuario"). El simple hecho de acceder al Sitio, implica que el Usuario acepta de manera expresa los términos y condiciones aquí descritos.
                    <br/>CONDICIONES DE USO
                    <br/>El Usuario del Sitio podrá beneficiarse de la información y hacer uso de los servicios y productos contenidos en el Sitio. Para que INVERSIONES FUTURO, LTDA. DE C.V., permita que los Usuarios hagan uso de los servicios y productos contenidos en el Sitio, los Usuarios deberán ingresar la información solicitada para que pueda ser contactado por los funcionarios o ejecutivos de INVERSIONES FUTURO, LTDA. DE C.V., lo cual será proporcionado por INVERSIONES FUTURO, LTDA. DE C.V., bajo los términos y condiciones del contrato respectivo que haya celebrado previamente con el Usuario.
                    Cualquier operación o transacción, derivada o relacionada con los productos y servicios que INVERSIONES FUTURO, LTDA. DE C.V., ofrece a sus clientes o usuarios en el Sitio, se regirá por los términos y condiciones establecidos en el contrato respectivo que INVERSIONES FUTURO, LTDA. DE C.V., haya celebrado previamente con el Usuario, y bajo los términos y condiciones aquí establecidas. Todos los productos y servicios que INVERSIONES FUTURO, LTDA. DE C.V., ofrece al público en general, son basados en la legislación aplicable y vigente.
                    INVERSIONES FUTURO, LTDA. DE C.V., se reserva el derecho de modificar el contenido del Sitio en cualquier momento, sin necesidad de previo aviso. El Usuario conviene en no utilizar dispositivos, software, o cualquier otro medio tendiente a interferir tanto en las actividades y/u operaciones del Sitio y/o de INVERSIONES FUTURO, LTDA. DE C.V., o en las bases de datos y/o información que se contenga en el mismo. El Usuario conviene en que cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derechos de autor, de propiedad industrial, secreto profesional, y/o a las prohibiciones aquí estipuladas, lo harán responsable, y INVERSIONES FUTURO, LTDA. DE C.V., tendrá el derecho de ejercitar las acciones legales pertinentes en su contra. Asimismo, en tal caso el Usuario será responsable de indemnizar los daños y perjuicios ocasionados a INVERSIONES FUTURO, LTDA. DE C.V. El Usuario conviene en que las violaciones de seguridad a los sistemas de cómputo y/o redes están prohibidas, y pueden generar delitos o incurrir en responsabilidad civil.
                    Ningún Usuario podrá utilizar el presente sitio para ofrecer ningún tipo de productos o servicios.
                    <br/>
                    CONFIDENCIALIDAD DE LA INFORMACIÓN
                    <br/>INVERSIONES FUTURO, LTDA. DE C.V., está obligado a mantener la confidencialidad de la información que obtenga del Usuario en los términos del Código de Comercio, Ley de Protección al Consumidor, y Declaración de Privacidad establecida en el presente documento, o en los términos de cualquier otra disposición legal aplicable. Así mismo, cualquier otra información que cada Usuario proporcione a INVERSIONES FUTURO, LTDA. DE C.V., a fin de que puedan acceder al Sitio o beneficiarse de la información contenida en el Sitio, es proporcionada en forma voluntaria por dicho Usuario, por lo que cada Usuario acepta como suficientes las medidas establecidas por el Sitio para asegurar el carácter confidencial de dicha información y de las comunicaciones que contengan dicha información (la "Información Personal"), por lo tanto, en este acto el Usuario libera a INVERSIONES FUTURO, LTDA. DE C.V., de cualquier responsabilidad en relación con la confidencialidad de la Información Personal. Cualquier información que sea transmitida entre el Usuario y las páginas o sitios de terceros ligados o vinculados al Sitio, será responsabilidad exclusiva de dichos terceros y del Usuario correspondiente.
                    <br/>RESPONSABILIDAD DE LA INFORMACIÓN
                    <br/>INVERSIONES FUTURO, LTDA. DE C.V., no asume ninguna responsabilidad relacionada o derivada con la veracidad de cualquier información contenida en el Sitio proporcionada por cualquier persona física o moral distinta a INVERSIONES FUTURO, LTDA. DE C.V. De la misma forma, INVERSIONES FUTURO, LTDA. DE C.V., no asume ninguna responsabilidad con respecto a cualquier daño o perjuicio que se pudiere llegar a causar a cualquier Usuario o a cualquier tercero, en relación con la utilización de cualquier información contenida en el Sitio, distinta de la información proporcionada por INVERSIONES FUTURO, LTDA. DE C.V.
                    <br/>ACCESO SECCIONES RESTRINGIDAS
                    <br/>El acceso por parte del Usuario a cualquier sección del Sitio protegida mediante claves, contraseñas o passwords (en lo sucesivo la "Clave de Seguridad"), se encuentra restringido únicamente a los Usuarios del Sitio que se encuentren debidamente registrados y autorizados para tales efectos, en los términos del contrato respectivo que haya celebrado el Usuario y INVERSIONES FUTURO, LTDA. DE C.V.
                    <br/>INVERSIONES FUTURO, LTDA. DE C.V., se reserva el derecho de tomar las medidas que considere necesarias para actuar en contra de la persona o personas que lleven a cabo actos dirigidos a accesar ilegítimamente cualquiera de dichas secciones protegidas.
                    <br/>SITIOS VINCULADOS
                    <br/>Cualquier vinculación con otro sitio, ya sea mediante ligas (links), marcos (frames) o de cualquier otra forma, que no se encuentre autorizada expresamente por INVERSIONES FUTURO, LTDA. DE C.V., será prohibida, por lo que INVERSIONES FUTURO, LTDA. DE C.V., se reserva el derecho de desactivar del Sitio cualquier liga o marco no autorizado. De la misma forma, INVERSIONES FUTURO, LTDA. DE C.V., no asume responsabilidad alguna respecto del contenido de cualquier sitio ligado o vinculado con el Sitio. Asimismo, el Usuario reconoce que cualquier acceso a otro sitio vinculado o ligado, será bajo su exclusiva responsabilidad, y en este acto libera a INVERSIONES FUTURO, LTDA. DE C.V., de cualquier responsabilidad derivada de cualquier asunto relacionado con lo anterior.
                    <br/>BIENES SERVICIOS DE TERCEROS ENLAZADOS
                    <br/>En relación con la información contenida en el Sitio, INVERSIONES FUTURO, LTDA. DE C.V., se limita exclusivamente a informar al Usuario sobre el contenido de dicha información. Toda la información y/o los bienes y/o servicios que se ofrecen a través del Sitio por sitios de terceros ligados o vinculados al Sitio, son suministrados por personas físicas o morales independientes a INVERSIONES FUTURO, LTDA. DE C.V., por lo que bajo ninguna circunstancia INVERSIONES FUTURO, LTDA. DE C.V. podrá ser considerado como el proveedor, vendedor o prestador de dichos bienes y/o servicios, por lo que en este acto el Usuario libera a INVERSIONES FUTURO, LTDA. DE C.V., de cualquier asunto relacionado con lo anterior, y INVERSIONES FUTURO, LTDA. DE C.V., no asume responsabilidad alguna por cualquier daño que se pudiere causar a cualquier tercero o a cualquier Usuario del Sitio, en relación con el contenido de la presente cláusula. El hecho de que se ofrezca información en el Sitio o en sitios ligados o vinculados, no implica la recomendación, garantía, patrocinio o aprobación por parte de INVERSIONES FUTURO, LTDA. DE C.V., con respecto de dicha información, bienes y/o servicios.
                    <br/>La disponibilidad de bienes y/o servicios ofertados por terceros por sitios ligados o vinculados, no es responsabilidad de INVERSIONES FUTURO, LTDA. DE C.V. En vista de lo anterior, INVERSIONES FUTURO, LTDA. DE C.V., no será responsable ante cualquier autoridad de cualquier naturaleza, por cualquier asunto relacionado con la venta, consumo, distribución, entrega, disponibilidad o prestación con respecto de cualquiera de los bienes y/o servicios ofertados por terceros o por sitios ligados o vinculados a través del Sitio.
                    <br/>FALLAS EN EL SISTEMA
                    <br/>INVERSIONES FUTURO, LTDA. DE C.V., no se responsabiliza por cualquier daño, perjuicio o pérdida en el equipo del Usuario causada por fallas en los sistemas de INVERSIONES FUTURO, LTDA. DE C.V., en su servidor, o en la Internet.
                    <br/>PROPIEDAD INTELECTUAL INDUSTRIAL
                    <br/>Los programas, bases de datos, redes y archivos que permiten al Usuario acceder y usar el Sitio, son propiedad de INVERSIONES FUTURO, LTDA. DE C.V., y están protegidos por las leyes y tratados internacionales de derechos de autor, marcas, patentes, modelos y diseños industriales.
                    <br/>El uso indebido y/o la reproducción total o parcial del contenido de los mismos queda prohibido, y dichas acciones se encuentran sujetas a sanciones civiles y penales, y serán objeto de todas las acciones judiciales pertinentes.
                    <br/>Cualquier Usuario o persona que tenga acceso al Sitio o a la información contenida en el mismo, reconoce expresamente que INVERSIONES FUTURO, LTDA. DE C.V., y/o empresas afiliadas y/o relacionadas, son los titulares, propietarios, y/o legítimos licenciatarios, de cualquier información que se encuentre en el Sitio, así como de cualquier marca, aviso, nombre comercial o cualquier otro derecho de propiedad industrial o derecho de autor que aparezca en el Sitio. Asimismo, el Usuario reconoce que INVERSIONES FUTURO, LTDA. DE C.V., ha sido el desarrollador del concepto de https://easycarca.com, por lo que es del conocimiento de dicho Usuario que dicha información y/o concepto se encuentran protegidos por las leyes Salvadoreñas y por los tratados internacionales de derechos de autor, por lo que cualquier reproducción, modificación, alteración, aprovechamiento o cualquier otro uso que se le dé a dicha información y/o concepto, se encuentra prohibido, y en este acto el Usuario del Sitio o cualquier persona que tenga acceso a la información antes señalada, se obliga a indemnizar a INVERSIONES FUTURO, LTDA. DE C.V., y/o cualquier empresa afiliada y/o relacionada, con respecto de cualquier violación a lo estipulado en el presente párrafo, ya sea en El Salvador o en el extranjero.
                    <br/>RESPONSABILIDAD
                    <br/>Tanto el acceso al Sitio como el uso que pueda hacerse de la información contenida en el mismo, son de la exclusiva responsabilidad de quien los realiza. El Usuario se compromete a notificar a INVERSIONES FUTURO, LTDA. DE C.V. en forma inmediata y por un medio fehaciente, cualquier uso no autorizado de su información, así como el ingreso al Sitio por terceros no autorizados. El Usuario se compromete a sacar en paz y a salvo a INVERSIONES FUTURO, LTDA. DE C.V. y a sus empresas subsidiarias y/o empresas relacionadas, con respecto de cualquier gasto, costa, daño y en general de cualquier pérdida que llegue a sufrir, en virtud de cualquier violación que se haya cometido con respecto de los términos y condiciones aquí contenidos o de cualquier ley aplicable, que se haya realizado o verificado por medio de la cuenta de acceso al Sitio de dicho Usuario.
                    <br/>SANCIONES
                    <br/>INVERSIONES FUTURO, LTDA. DE C.V.  se reserva el derecho de condicionar, suspender o cancelar, temporal o definitivamente el acceso de un Usuario al Sitio, en cualquier momento, e iniciar las acciones legales que correspondan, si el Usuario quebranta cualquiera de las estipulaciones de los términos y condiciones aquí establecidos, o comete cualquier otro acto contrario a las leyes aplicables vigentes, o bien si no pudiera verificarse la identidad del Usuario, o cualquier información proporcionada por el mismo fuere falsa.
                    <br/>JURISDICCIÓN APLICABLE
                    <br/>Para todo lo relativo a la interpretación y cumplimiento de los presentes términos y condiciones, las partes se someten a las leyes aplicables y a los Tribunales competentes de la Ciudad de San Salvador, El Salvador, Centro América, y renuncian a cualquier otra jurisdicción que por razón de sus domicilios presentes o futuros pudiere corresponderles.
                </Text>
            </Box>

        </Layout >
    )
}

export default Terminos_condiciones